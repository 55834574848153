@tailwind base;
@tailwind components;
@tailwind utilities;


.react-responsive-modal-modal {
  width: 550px !important; 
  border-radius: 20px;
  height: 640px
}

input {
  box-shadow: none  !important;
}